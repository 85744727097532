$optional-color: #ff612f;
$black-color: #080a3c;
$white-color: #ffffff;
$transition: .5s;

/*================================================
Go Top CSS
=================================================*/
.back-to-top {
	.top {
        position: fixed;
        cursor: pointer;
        bottom: 15px;
        right: 15px;
        color: $black-color;
        background-color: $white-color; 
        z-index: 4;
        width: 45px;
        text-align: center;
        height: 45px;
        border-radius: 50%;
        font-size: 40px;
        transition: .9s;
        line-height: 55px;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: #bc2e31;
            color: $white-color;
            transition: $transition;
            transform: translateY(-10px);
        }
    }
}