.contact-form-footer {
    .form-control {
        font-size: 16px;
    }
    .form-control:not(textarea) {
        height: 4rem;
    }
    .form-group {
        margin-bottom: 1.5rem;
    }
}
.box-shadow-none {
    box-shadow: none;
}
.thanks-para {
    color: #FFF;
    margin-left: 15px;
}
.custom-select {
    background: #FFF;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    padding-right: 1.75rem;
}