.portfollio-section {
    padding: 7rem 0;

    .portfolio-content {
        h3 {
            font-size: 2.4rem;
            margin: 0;
        }

        .project-category {
            font-size: 1.8rem;
            font-weight: 500;
            margin-bottom: 2rem;
        }

        p {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}


/*Responsive Css*/
@media (max-width:1199px) {
    .portfolio-content {
        margin-top: 20px;
    }
}
@media (max-width:767px) {
    .portfollio-section {
        .portfolio-content {
            h3 {
                font-size: 2rem;
            }
            .project-category {
                font-size: 16px;
            }
            p {
                font-size: 14px;
            }
        }
    }
}