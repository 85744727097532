$font-family: 'Poppins', sans-serif;
$main-color: #bc2e31;
$optional-color: #ff612f;
$black-color: #080a3c;
$white-color: #ffffff;
$paragraph-color: #4a6f8a;
$font-size: 15px;
$transition: .5s;

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    border-radius: 2px;
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: $white-color;
    transition: $transition;
    display: block;

    .post-image {
        overflow: hidden;
        position: relative;

        img {
            transition: $transition;
        }
    }

    .post-content {
        padding: 25px;

        h3 {
            line-height: 1.4;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            font: {
                size: 20px;
                weight: 600;
            }

            ;

            a {
                display: inline-block;
            }
        }

        .post-info {
            -ms-flex-align: center !important;
            align-items: center !important;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;

            margin: {
                top: 15px;
            }

            ;

            .post-by {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                position: relative;
                max-width: 50%;
                padding-left: 52px;

                img {
                    width: 43px;
                    height: 43px;
                    border-radius: 50%;
                    border: 1px solid #dde4ff;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                h6 {
                    margin-bottom: 0;
                    color: #080a3c;

                    font: {
                        size: $font-size;
                        weight: 500;
                    }
                }
            }

            .details-btn {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                text-align: right;
                max-width: 50%;

                a {
                    display: inline-block;
                    width: 43px;
                    height: 43px;
                    position: relative;
                    border-radius: 50%;
                    background-color: #ebefff;
                    color: $black-color;
                    text-align: center;
                    font-size: 25px;

                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        margin: {
                            left: auto;
                            right: auto;
                        }

                        ;
                    }

                    &:hover {
                        color: $white-color;
                        background-color: $black-color;
                    }
                }
            }
        }
    }

    &:hover {
        .post-image {
            img {
                transform: scale(1.2) rotate(5deg);
            }
        }
    }
}

.blog-notes {
    margin-top: 10px;
    text-align: center;

    p {
        line-height: initial;

        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
}

.single-blog-post-item {
    margin-bottom: 30px;
    position: relative;
    background-color: #fffaf3;

    .post-image {
        border-radius: 5px 5px 0 0;

        a {
            border-radius: 5px 5px 0 0;
            overflow: hidden;

            img {
                border-radius: 5px 5px 0 0;
                transition: $transition;
                width: 100%;
            }
        }
    }

    .post-content {
        padding: 20px;

        .category {
            display: inline-block;
            margin-bottom: 10px;
            color: $paragraph-color;
            text-transform: uppercase;

            font: {
                weight: 500;
                size: 14px;
            }

            ;

            &:hover {
                color: $optional-color;
            }
        }

        h3 {
            margin-bottom: 12px;
            line-height: 1.4;

            font: {
                size: 22px;
                weight: 600;
            }

            ;

            a {
                display: inline-block;
            }
        }

        .post-content-footer {
            list-style-type: none;
            padding-left: 0;

            margin: {
                bottom: 0;
                left: -7px;
                right: -7px;
                top: 18px;
            }

            ;

            li {
                color: $paragraph-color;
                font-size: 15px;
                position: relative;

                padding: {
                    left: 28px;
                    right: 7px;
                }

                ;

                .post-author {
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }

                    span {
                        color: $optional-color;
                    }
                }

                i {
                    color: $main-color;
                    position: absolute;
                    left: 7px;
                    top: 3px;
                }

                &:first-child {
                    padding-left: 7px;
                }
            }
        }
    }

    &:hover {
        .post-image {
            a {
                img {
                    transform: scale(1.07);
                }
            }
        }
    }
}

.blog-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: -105px;

            [class*=owl-] {
                color: $optional-color;
                font-size: 35px;
                margin: 0;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                padding: 3px 0 0 0 !important;
                background: #fcf8f4;
                border-radius: 50%;
                transition: $transition;

                &.owl-next {
                    margin-left: 10px;
                }

                &:hover {
                    background-color: $optional-color;
                    color: $white-color;
                }
            }
        }
    }
}


@media (max-width:991px) {
    .single-blog-post {
        .post-content {
            h3 {
                font-size: 1.8rem;
            }
        }
    }
}