.why-choose {
    overflow: hidden;
    background-color: #f3fbff;
}
.list-design {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        position: relative;
        padding-left: 2rem;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 8px;
            width: 8px;
            height: 8px;
            background-color: #BC2E31;
        }
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}