.about-section {
    position: relative;
    overflow: hidden;

    .about-stats {
        background-color: #BC2E31;
        text-align: center;
        border-radius: 0 0 15px 15px;
        padding: 2rem;

        .years-count {
            font-size: 5rem;
            font-weight: 700;
            color: #FFF;
        }

        span {
            font-size: 2.6rem;
            color: #FFF;
            display: block;
        }
    }

    .our-company-media {
        margin-top: 4rem;
        position: relative;

        &.border-radius {
            border-radius: 20px 20px 0px 0px;
        }
    }

    .about-content {
        padding-left: 3rem;
    }
}

.company-stats {
    margin-top: 3rem;

    .stats-bx {
        border: 1px solid #f2f2f2;
        padding: 2rem;
        text-align: center;
        border-radius: 10px;
        transition: .3s;
        cursor: pointer;

        .count-stats {
            font-size: 4rem;
            font-weight: 700;
            color: #BC2E31;
            transition: .3s;
        }

        p {
            font-size: 2rem;
            margin: 0;
            color: #04213F;
            transition: .3s;
        }

        &:hover {
            background-color: #04213F;
            border-color: #04213F;

            .count-stats {
                color: #FFF;
            }

            p {
                color: #FFF;
            }
        }
    }
}

.rebranding-image {
    margin-bottom: 3rem;
    border-radius: 2rem;
    overflow: hidden;
}

.services-section-2 {
    .d-flex {
        padding: 0;

        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8) {
            .services-bx {
                // background-color: #04213F;
                background-color: #f3f7ff;
            }
        }

        .services-bx {
            width: 100%;
            text-align: center;
            border-radius: 0;
            margin-bottom: 0;
            // background-color: #BC2E31;
            background-color: #ebf2ff;
            border: 0;

            h3 {
                color: #04213F;
                margin: 0;
                min-height: 50px;
            }

            .icon {
                margin-left: auto;
                margin-right: auto;
                border-radius: 100%;
                transition: .3s;
            }

            &:hover {
                .icon {
                    background-color: #bc2e31;
                }

                h3 {
                    color: #bc2e31;
                }
            }
        }
    }
}

.industry-section {
    background-color: #f3f7ff;
}


.founder-detail {
    position: relative;

    .founder-image {
        border: 8px solid #04213F;
        border-radius: 15px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .founder-name {
        background-color: #BC2E31;
        padding: 15px;
        margin-top: -20px;
        z-index: 1;
        position: relative;
        border-radius: 0 0 15px 15px;
        text-align: center;

        h2 {
            font-size: 4rem;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;
            color: #770608;
        }

        p {
            font-size: 18px;
            margin-bottom: 0;
            color: #FFF;
        }

        a {
            img {
                position: absolute;
                top: -50px;
                right: 20px;
                animation: el-bounce 3s infinite linear alternate;
            }
        }

        .linkedin-arrow-icon {
            position: absolute;
            top: -20px;
            right: 60px;
            transform: rotate(-30deg);

            img {
                animation: el-bounce 3s infinite linear alternate;
            }
        }

        @keyframes el-bounce {
            0% {
                transform: translateY(0)
            }

            50% {
                transform: translateY(-10px)
            }

            100% {
                transform: translateY(5px)
            }
        }
    }
}

.company-descripition {
    h2 {
        margin-bottom: 2rem;
        font-size: 4.6rem;
        font-weight: 700;
    }
}

.box-design {
    box-shadow: 0 0 15px rgb(242, 242, 242);
    border: 1px solid #f2f2f2;
    padding: 2.5rem 3.5rem;
    border-radius: 15px;
    text-align: center;
    position: relative;
    overflow: hidden;

    .icon {
        background-color: #bc2e31;
        width: 8rem;
        height: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 2rem;
        padding: 1rem;
        border-radius: 20px 0;
        transition: .3s;

        img {
            filter: brightness(0) invert(1);
            transition: .3s;
        }
    }

    h2 {
        font-size: 4.6rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.8rem;
    }

    &:hover {
        .icon {
            background-color: #080a3c;
        }

        h2 {
            color: #bc2e31;
        }
    }
}

.our-success-section {
    .success-bg {
        background-color: #04213f;
        padding: 50px;
        text-align: center;
        border-radius: 15px;
    }

    .success-bx {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05);
        padding: 20px;
        border-radius: 5px;

        h3 {
            color: #FFF;
            font-size: 3.2rem;
            margin-bottom: 0;
        }

        p {
            color: #FFF;
            margin-bottom: 0;
            font-size: 1.8rem;
        }
    }
}

.our-team-section {
    .team-bx {
        position: relative;

        img {
            width: 100%;
        }

        .team-details {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 36px 20px 30px;
            z-index: 99;
            text-align: center;

            &:before {
                content: "";
                clip-path: polygon(0 24%, 100% 0, 100% 100%, 25% 100%, 0 100%);
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;
                right: 0;
                background-color: rgba(0, 7, 11, .8);
                z-index: -1;
            }

            h3 {
                font-size: 2.4rem;
                color: #FFF;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
                color: #bc2e31;
            }
        }

        .socials-icon {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translate(-50%, 0);
            background-color: #fff;
            display: inline-flex;
            z-index: 99;

            a {
                height: 4rem;
                width: 4rem;
                color: #747474;
                font-size: 14px;
                border: 1px solid #e9e6e6;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 15px;
                    height: 15px;
                    transition: .3s;
                    color: #04213F;
                }

                &:hover {
                    background-color: #04213F;

                    svg {
                        color: #FFF;
                    }
                }
            }
        }
    }
}

.blog-item {
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
    transition: 0.5s;
    padding: 15px;
    display: block;
    overflow: hidden;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: none;
    }
}

.bg-f2f2f2 {
    .single-services-box-item {
        overflow: initial;
        margin-top: 50px;
        background-color: #FFF;
        padding: 50px;
        border: 2px solid #c1c1c1;
        .icon {
            position: absolute;
            top: -32.5px;
            left: 50px;
        }
        &:before, &:after {
            display: none;
        }

    }
    [class*="col-"] {
        &:nth-child(2) {
            .icon {
                background-color: #dafdf2;
            }
        }
    
        &:nth-child(3) {
            .icon {
                background-color: #fddae0;
            }
        }
    
        &:nth-child(4) {
            .icon {
                background-color: #daecfd;
            }
        }
    
        &:nth-child(5) {
            .icon {
                background-color: #fddafc;
            }
        }
    
        &:nth-child(6) {
            .icon {
                background-color: #ebdafd;
            }
        }
    
        &:nth-child(7) {
            .icon {
                background-color: #fdeeda;
            }
        }
    
        &:nth-child(8) {
            .icon {
                background-color: #faaaac;
            }
        }
    }
}

.single-services-box-item {
    width: 100%;
    background-color: #eff5ff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: .5s;
    padding: 3rem;
    margin-bottom: 2.5rem;
    transition: .3s;

    .icon {
        width: 6.5rem;
        height: 6.5rem;
        position: relative;
        background-color: #dae7fd;
        border-radius: 5px;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;

        img {
            filter: brightness(0);
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 22px;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        line-height: 1.7;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 4px;
        background: #080a3c;
        opacity: 0;
        visibility: hidden;
        bottom: 0;
        transition: .5s;
        top: auto;
        z-index: auto;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }

    &:hover {
        transform: translateY(-10px);

        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            width: 50%;
        }
    }
}

.about-industry-image {
    padding-left: 5rem;

    [class*="col-"] {
        padding: 0;
    }

    .row {
        margin: 0;
    }

    .vimerse-icon {
        padding: 5rem 4rem;
        margin: -.5px;
        background-color: #fff;
        text-align: center;
        border: 1px solid #c6c6c6;
    }
}

.service-item {
    background-color: #FFFFFF;
    padding: 2.5rem;
    margin-top: 2rem;
    border-radius: 15px;
    overflow: hidden;
    border-bottom: 5px solid #bc2e31;
    border-top: 5px solid transparent;
    transition: .3s;

    &:hover {
        border-top: 5px solid #031b42;
        border-bottom: 5px solid transparent;
    }

    p {
        font-size: 14px;
    }
}

.map {
    background-color: #f3f7ff;
    padding: 8px;
    display: flex;
}

.address-item {
    padding: 15px;
    background-color: #FFF;
    border-radius: 10px;
    width: 100%;

    h3 {
        line-height: 1.4;
        margin-bottom: 10px;

        .flag-icon {
            width: 6rem;
            height: 4rem;
            object-fit: cover;
            margin-right: 1.5rem;
        }
    }

    .contact-item {
        span {
            font-weight: 500;
        }

        a {
            color: #bc2e31;

            &:hover {
                color: #04213f;
            }
        }
    }

    .contact-item+.contact-item {
        margin-top: 8px;
    }
}

.office-address {
    padding-left: 15px;
    padding-right: 15px;
}

// .process-item {
//     text-align: center;
//     background-color: #2957a4;
//     padding: 15px;
//     border-radius: 2rem;
//     position: relative;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     transition: transform 0.8s;
//     transform-style: preserve-3d;

//     h3 {
//         margin: 2rem 0 0;
//         color: #FFF;
//     }

//     &:hover .flip-box-inner {
//         transform: rotateX(180deg);
//     }

//     .flip-box-front,
//     .flip-box-back {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         -webkit-backface-visibility: hidden;
//         backface-visibility: hidden;
//     }

//     .flip-box-back {
//         background-color: dodgerblue;
//         color: white;
//         transform: rotateX(180deg);
//     }
// }

.flip-box {
    width: 100%;
    height: 440px;
    perspective: 1000px;

    .flip-box-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    &:hover .flip-box-inner {
        transform: rotateX(180deg);
    }

    .flip-box-front,
    .flip-box-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: 2rem;
        overflow: hidden;
    }

    .flip-box-front {
        // background-color: #bbb;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h3 {
            margin: 2rem 0 0;
        }
    }

    .flip-box-back {
        background-color: #2957a4;
        color: white;
        transform: rotateX(180deg);
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: #FFF;
            font-size: 20px;
            text-align: center;
        }
    }
}

.client-logos {
    padding: 0 0 5rem;

    .client-logos-slider {
        padding: 1.5rem 0.5rem;
        background-color: #f4f6fc;

        .logo-item {
            border: 1px solid #e8e8e8;
            display: flex !important;
            align-items: center;
        }

        .slick-slide {
            padding: 1rem !important;

            >div {
                display: flex;
            }
        }

        .slick-next,
        .slick-prev {
            display: none !important;
        }
    }
}

.tech-item {
    display: block;
    padding: 10px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #FFF;

    img {
        height: 50px;
        object-fit: contain;
        margin-bottom: 8px;
    }

    p {
        margin: 0;
        font-size: 12px;
    }
}

.box-design2 {
    .image {
        img {
            height: 340px;
            object-fit: cover;
        }
    }

    .content {
        padding: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 340px;
    }
}

.box-border-bottom-design {
    .shadow-box {
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .16);
        padding: 20px;
        border-radius: 10px;
        border-bottom: 10px solid #2957a4;

        .icon {
            background-color: #f2f2f2;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 18px;
            width: 60px;
            height: 60px;
            padding: 7px;
            margin-bottom: 10px;
        }
    }

    .col-lg-6:nth-child(2),
    .col-lg-3:nth-child(2) {
        .shadow-box {
            border-color: #bc2e31;
        }
    }

    .col-lg-6:nth-child(3),
    .col-lg-3:nth-child(3) {
        .shadow-box {
            border-color: #169fed;
        }
    }

    .col-lg-6:nth-child(4),
    .col-lg-3:nth-child(4) {
        .shadow-box {
            border-color: #ffdf73;
        }
    }

    .col-lg-6:nth-child(5),
    .col-lg-3:nth-child(5) {
        .shadow-box {
            border-color: #beb9f6;
        }
    }

    .col-lg-6:nth-child(6),
    .col-lg-3:nth-child(6) {
        .shadow-box {
            border-color: #8fd5e7;
        }
    }
}

.hover-icon-effect {
    display: flex;
    align-items: flex-start;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    margin-bottom: 2rem;

    .bounce-img {
        min-width: 6rem;
        min-height: 6rem;
        max-width: 6rem;
        max-height: 6rem;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .3s;
        transition-property: all;
        background-color: #eaefeb;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        padding: 1rem;
        border-radius: 5px;

        img {
            display: block;
            max-height: 40px;
            max-width: 40px;
        }
    }

    h3 {
        margin-bottom: 0;
    }

    &:hover {
        .bounce-img {
            transform: translateY(-1rem);
        }
    }
}

.job-list {
    list-style: none;
    padding: 0;
    margin: 4rem 0 0;
    display: flex;
    flex-wrap: wrap;

    .job-item {
        border: 1px solid #e5e5e5;
        background-color: #FFF;
        padding: 1.5rem;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 3rem;

        p {
            span {
                margin-left: 2rem;
            }
        }

        img {
            filter: brightness(0) invert(1);
            transition: .3s;
        }

        a:hover {
            transform: translateY(-1rem);
        }

        h3 {
            margin: 0;
        }
    }
}

.hire-process-box {
    display: flex;
    flex-direction: column;

    .process-count {
        font-size: 40px;
        height: 88px;
        width: 88px;
        border-radius: 50%;
        font-weight: 700;
        background-color: rgb(188 46 49 / 20%);
        color: #bc2e31;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hire-box {
        box-shadow: 0 2px 4px rgba(0, 51, 78, .1);
        background-color: #fff;
        border: 1px solid #f2f2f2;
        border-radius: 15px;
        padding: 25px;
        margin-top: -33px;
    }
}

.hire-box {
    padding: 3rem;
    background-color: #eceff0;
    border-radius: 10px;
    transition: .3s;

    h3,
    p {
        transition: .3s;
    }

    .hire-list-icon {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            width: calc(100%/6 - 5px);
            display: flex;

            &:not(:last-child) {
                margin-right: 5px;
            }

            .tech-box {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 15px 0 0;

                img {
                    border: 1px solid #f2f2f2;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    padding: 10px;
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    margin: 0 auto 8px;
                    display: block;
                }

                span {
                    font-size: 14px;
                    transition: .3s;
                }
            }
        }
    }

    &:hover {
        background-color: #031b42;

        h3,
        p,
        span {
            color: #FFF;
        }
    }
}

.column-count-2 {
    column-count: 2;
}

@media (max-width:1199px) {
    .client-logos {
        .client-logos-slider {
            padding: 2.5rem 0.5rem;
        }

        .slick-dots {
            bottom: 10px;
        }
    }

    .flip-box {
        height: 350px;

        .flip-box-front {
            h3 {
                min-height: 65px;
            }
        }

        .flip-box-back {
            p {
                font-size: 16px;
            }
        }
    }

    .single-services-box-item {
        padding: 2rem;
    }
}

@media (max-width:991px) {

    .flip-box {
        height: unset;
        perspective: unset;
        margin-bottom: 20px;

        .flip-box-front {
            position: initial;

            h3 {
                min-height: unset;
                margin: 0;
                font-size: 3rem;
            }
        }

        .flip-box-back {
            position: initial;
            backface-visibility: visible;
            background-color: transparent;
            margin-top: 20px;
            transform: unset;
            padding: 0;

            p {
                color: #000;
            }
        }

        &:hover {
            .flip-box-inner {
                transform: unset;
                transform-style: unset;
            }
        }
    }

    .single-services-box-item {
        h3 {
            font-size: 2rem;
        }

        p {
            font-size: 14px;
        }
    }
}

@media (max-width:767px) {

    .flip-box {
        .flip-box-front {
            h3 {
                font-size: 2.4rem;
            }
        }
    }
    .column-count-2 {
        column-count: 1;
    }
}