.footer-section {
    background-color: #04213f;
    padding: 5rem 0 0;

    .about-footer {
        .footer-logo {
            margin-bottom: 2.5rem;
        }

        .contact-info-row {
            .col {
                display: flex;
                align-items: center;
                .icon {
                    width: 5rem;
                    height: 5rem;
                    background-color: #FFFFFF;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    margin-right: 1.5rem;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                a {
                    width: calc(100% - 6.5rem);
                    color: #FFF;
                    transition: .3s;
                    span {
                        font-weight: 500;
                        display: block;
                    }
                    &:hover {
                        color: #bc2e31;
                        padding-left: 2px;
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }
    }
    .useful-links {
        h2 {
            font-size: 2.4rem;
            margin-bottom: 2.5rem;
            color: #FFFFFF;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                a {
                    color: #FFF;
                    transition: .3s;
                    padding: 5px 0;
                    font-size: 16px;
                    &:hover {
                        color: #bc2e31;
                        padding-left: 7px;
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
    .social-icons {
        margin-top: 3rem;
        p {
            font-size: 1.8rem;
            color: #FFF;
            margin:0 0 1rem;
        }
        ul {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                &:not(:last-child) {
                    margin-right: 1.5rem;
                }
                a {
                    background-color: #FFF;
                    transition: .3s;
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    padding: 1rem;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                    &:hover {
                        background-color: #bc2e31;
                        color: #FFF;
                    }
                }
            }
        }
    }
    .copyright-area {
        margin-top: 30px;
        border-top: 1px solid #484848;
        padding-top: 20px;
        padding-bottom: 20px;
        p {
            color: #FFF;
            font-size: 14px;
            margin: 0;
        }
        a {
            color: #FFF;
            font-size: 14px;
            transition: .3s;
            &:hover {
                color: #bc2e31;
            }
        }
    }
}

@media (max-width:991px) {
    .footer-section {
        .about-footer {
            .contact-info-row {
                .col {
                    .icon {
                        width: 4rem;
                        height: 4rem;
                    }
                }
            }
        }
    }
    .useful-links {
        margin-top: 2.5rem;
    }
}
@media (max-width:767px) {
    .footer-section {
        padding: 3rem 0 0;
        .useful-links {
            h2 {
                font-size: 1.8rem;
            }
        }
    }
}
