:root {
  --primaryColor: #080a3c;
}

html {
  font-size: 62.5%;
}

body {
  color: var(--primaryColor);
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

a {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: var(--primaryColor);
  text-decoration: none;
  outline: 0 !important;
}

a:hover {
  text-decoration: none;
  color: #bc2e31;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

button,
input {
  outline: 0 !important;
}

img {
  max-width: 100%;
  height: auto;
}

.bg-f2f2f2 {
  background-color: #f2f2f2;
}

.bg-f3f7ff {
  background-color: #f3f7ff;
}

.bg-ffffff {
  background-color: #FFFFFF;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.bg-f4f6fc {
  background-color: #f4f6fc;
}

.bg-f8fbfa {
  background-color: #f8fbfa;
}

.bg-f4f5fe {
  background-color: #f4f5fe;
}

.bg-f4e9da {
  background-color: #f4e9da;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-40 {
  padding-bottom: 40px;
}

.tx-center {
  text-align: center;
}

.mwidth-1000 {
  max-width: 1000px !important;
}

.max-width-1290 {
  max-width: 1290px;
}

.bg-f9f9f9 {
  background-color: #f9f9f9;
}

.blt-radius-0 {
  border-radius: 0px 30px 30px 30px !important;
}

p {
  color: #080a3c;
  margin-bottom: 12px;
  line-height: 1.8;
  font-size: 15px;
}

p:last-child {
  margin-bottom: 0;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.mr-3 {
  margin-right: 15px;
}

/* Section title */
.section-title {
  text-align: center;
  // max-width: 575px;
  max-width: 1000px;
  margin-left: auto;
  margin-bottom: 40px;
  margin-right: auto;

  h1 {
    font-size: 4.6rem;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  h2 {
    margin-bottom: 0;
    font-size: 3.8rem;
    font-weight: 700;
    text-transform: capitalize;

    span {
      display: inline-block;
      color: #ff612f;
    }
  }

  &.text-left {
    max-width: 576px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;

    .sub-title {
      font-weight: 600;
      color: #ff612f;
      display: block;
      margin-bottom: 10px;
    }
  }

  &.max-width-unset {
    max-width: unset;
  }

  p {
    font-size: 1.8rem;
    color: #04213F;
    margin: 1rem 0 0;
  }
}

.banner-section {
  .section-title {
    h1 {
      font-size: 4.6rem;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: capitalize;
    }
  }
}

/* default-btn&optional-btn */
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: #ffffff;
  background-color: #bc2e31;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  padding: 13px 25px;

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: #bc2e31;
    -webkit-transition: width 0.6s ease-in-out, height 0.5s ease-in-out;
    transition: width 0.6s ease-in-out, height 0.5s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 5px;
  }

  &:hover,
  &:focus {
    color: #ffffff;
    -webkit-box-shadow: 0px 5px 28.5px 1.5px rgba(19, 196, 161, 0.3) !important;
    box-shadow: 0px 5px 28.5px 1.5px rgba(19, 196, 161, 0.3) !important;

    span {
      width: 225%;
      height: 562.5px;
    }
  }
}

.subtitle {
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

.repeat-section {
  padding: 7rem 0;
  overflow: hidden;
}

.text-red {
  color: #bc2e31;
}

.slick-next,
.slick-prev {
  width: 4rem;
  height: 4rem;
  padding: 10px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer !important;
  transition: .3s;

  &:focus {
    background-color: #FFF;
  }

  &:hover {
    background-color: #bc2e31;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='35.714' viewBox='0 0 25 35.714'%3E%3Cpath id='primary' d='M28.214 37.714a1.786 1.786 0 0 1-1.071-.357L5.714 21.286a1.786 1.786 0 0 1 0-2.857L27.143 2.357a1.786 1.786 0 0 1 2.143 2.857L9.768 19.857 29.286 34.5a1.786 1.786 0 0 1-1.071 3.214Z' transform='translate(-5 -2)' fill='%23fff'/%3E%3C/svg%3E");
    }
  }

  &:before {
    color: #080a3c;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.061' height='34.373' viewBox='0 0 24.061 34.373'%3E%3Cpath id='primary' d='M27.342 36.373a1.719 1.719 0 0 1-1.031-.344L5.687 20.561a1.719 1.719 0 0 1 0-2.75L26.311 2.344a1.719 1.719 0 0 1 2.062 2.75L9.589 19.186 28.374 33.279a1.719 1.719 0 0 1-1.031 3.094Z' transform='translate(-5 -2)'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
  }

  &:hover {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='35.714' viewBox='0 0 25 35.714'%3E%3Cpath id='primary' d='M28.214 37.714a1.786 1.786 0 0 1-1.071-.357L5.714 21.286a1.786 1.786 0 0 1 0-2.857L27.143 2.357a1.786 1.786 0 0 1 2.143 2.857L9.768 19.857 29.286 34.5a1.786 1.786 0 0 1-1.071 3.214Z' transform='translate(-5 -2)' fill='%23fff'/%3E%3C/svg%3E");
    }
  }
}

.slick-next {
  right: 0;

  &:before {
    transform: rotate(-180deg);
  }
}

.slick-prev {
  left: 0;
}

.bg-v-icon {
  background: #FFF url(../public/images/bg-icon-overlay.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.border-radius {
  border-radius: 15px;
}

textarea {
  resize: none;
}

textarea.form-control {
  min-height: 8rem;
}

.horizental-bar {
  left: 15px;
  right: 35px;
  height: 15px;
  bottom: -7.5px;
  max-width: 100%;
  position: absolute;
  -webkit-animation: move-right 3s linear infinite;
  animation: move-right 3s linear infinite;

  &.bg-blue {
    background-color: #04213F;
  }
}

@keyframes move-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.dots-shape-animate {
  animation: moveScale 3s linear infinite;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes moveLeftBounce {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1)
  }

  75% {
    transform: scale(1.75);
    opacity: 1
  }

  100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes moveScale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.8);
  }

  100% {
    transform: scale(1);
  }
}

.btn-primary {
  background-color: #BC2E31;
  border-color: #BC2E31;
  color: #FFF;

  span {
    background-color: #080a3c;
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;

  >* {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.text-link {
  color: #bc2e31;
  font-weight: 500;

  svg {
    transition: all ease .4s;
    font-weight: 900;
  }

  &:hover {
    svg {
      margin-left: 5px;
    }
  }
}

.services-bx {
  background-color: rgb(255, 255, 255);
  padding: 2rem;
  border-radius: 10px;
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.01); */
  margin-bottom: 30px;
  border: 1px solid #e0e6f761;
  transition: 0.3s;
  position: relative;
  z-index: 1;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   right: -2rem;
  //   bottom: -2rem;
  //   top: 2rem;
  //   left: 2rem;
  //   width: 100%;
  //   height: 100%;
  //   border: 1px solid #04213F;
  //   z-index: -2;
  //   border-radius: 10px;
  // }
  // &:before {
  //   content: "";
  //   position: absolute;
  //   right: -1rem;
  //   bottom: -1rem;
  //   top: 1rem;
  //   left: 1rem;
  //   width: 100%;
  //   height: 100%;
  //   border: 1px solid #04213F;
  //   z-index: -2;
  //   border-radius: 10px;
  // }

  .icon {
    width: 6.5rem;
    height: 6.5rem;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    background-color: #eaefeb;
    border-radius: 5px;

    img {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #080a3c;
    font-weight: 600;
  }

  p {
    margin: 0;
    font-size: 16px;
  }

  .readmore-btn {
    display: flex;
    margin: 15px 0 0;
  }

}

.industry-bx {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
  width: 100%;

  .industry-detail {
    padding: 1.5rem;

    h3 {
      font-size: 2rem;
      margin-bottom: 10px;
      color: #080a3c;
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: 16px;
    }

    .readmore-btn {
      display: flex;
      margin: 15px 0 0;
    }
  }
}

.image-radius-design {
  border-radius: 15px;
  overflow: hidden;
}

.services-section {
  background-color: #f3f7ff;
}

.f-size-18 {
  font-size: 1.8rem;
}

.banner-section {
  .section-title {
    margin-bottom: 1.5rem;
  }

  .about-content {
    // max-width: 560px;
    max-width: 670px;
  }
}

h3 {
  font-size: 2.2rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

.list-design-2 {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
  column-count: 3;

  li {
    background-color: #f1f1f1;
    color: #04213f;
    box-shadow: 4px 5px 3px #e1e1e1;
    border: 1px solid #f1f1f1;
    border-radius: 30px;
    padding: 13px 20px;
    font-size: 1.6rem;
    margin: 0 0 15px;
    transition: .3s;

    &:hover {
      background-color: #04213F;
      color: #FFF;
    }
  }
}

.form-resume-submit {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  box-shadow: -10px -9px 70px 20px rgb(0 0 0 / 11%);

  label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
  }

  .form-control {
    height: 40px;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 15px;

    span {
      input {
        margin-right: 5px;
      }
    }

    span+span {
      margin-left: 20px;
    }
  }

  .error {
    color: red;
  }
}

.thank-you-message {
  text-align: center;

  img {
    max-width: 300px;
    margin: 0 auto;
  }

  p {
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    margin: 2rem 0 0;
  }
}

.image-title-box {
  background-color: #FFF;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  width: 100%;
  border: 1px solid #f1f1f1;

  img {
    background-color: #e7e7e7;
  }

  h3 {
    font-size: 2rem;
    margin: 1.5rem 0;
  }
}

.cta {
  background-color: #080a3c;
  padding: 4rem 0;

  h3 {
    color: #FFF;
    margin-bottom: 0;
    position: relative;
    padding-top: 1.5rem;
    font-size: 3rem;
    line-height: 1.4;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      right: 0;
      top: 0px;
      background: linear-gradient(to left, transparent, #BC2E31);
    }
  }

  p {
    color: #FFF;
  }
}

.contact-section {
  .thanks-para {
    color: green;
  }
}

.mw-100 {
  max-width: 100% !important;
}

.error-page-section {
  max-width: 800px;
  margin: 80px auto;
  display: flex;
  align-items: center;
  gap: 50px;

  .error-image {
    margin: 0 auto 30px;
    display: block;
    width: 320px;
  }

  .error-content {
    width: calc(100% - 320px);
    text-align: left;

    h1 {
      font-size: 40px;
      margin-bottom: 15px;
    }

    p {
      font-size: 18px;
      margin-bottom: 0;
    }
  }
}

.blog-featured {
  text-align: center;
  margin-bottom: 40px;

  img {
    margin: 0 auto 25px;
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 15px;
  }
}

.blog-page-detail {
  h2 {
    font-size: 3rem;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 2.6rem;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 2.2rem;
    margin-bottom: 15px;
    margin-left: 20px;
  }

  h4+p {
    margin-left: 20px;
  }

  h4+p+p {
    margin-left: 20px;
  }

  h4+p+ul {
    margin-left: 20px;
  }

  h4+p+ul+p {
    margin-left: 20px;
  }

  h5 {
    font-size: 2rem;
    margin-left: 40px;
  }

  h5+p {
    margin-left: 40px;
  }

  p {
    font-size: 16px;
  }

  .list-design {
    padding-left: 15px;
  }

  .list-design+h3,
  .list-design+h4,
  .list-design+p {
    margin-top: 20px;
  }
}

.blog-sidebar {
  position: relative;

  .sticky-element {
    position: sticky;
    top: 100px;
  }

  .discuss-project-form {
    padding: 25px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
  }
}

.blog-sec-descripition {
  img {
    max-height: 400px;
    margin: 15px 0 30px;
  }
}

.salesforce-training-banner {
  background: #04213F url(../public/images/salesforce-training-banner.jpg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 86%);
    z-index: -1;
  }

  .container {
    max-width: 1280px;
  }

  .section-title {
    margin-bottom: 50px;

    h1 {
      color: #FFF;
    }

    p {
      color: #FFF;
      font-size: 26px;
      margin-top: 25px;
    }
  }

  p {
    color: #FFF;
    font-size: 20px;
  }

}

.in-list {
  list-style: none;
  padding: 15px 0;
  margin: 0 0 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #BC2E31;
  border-top: 1px solid #BC2E31;

  li {
    flex-basis: 50%;
    padding: 10px;
    color: #000;
    font-size: 18px;

    &:last-child {
      flex-basis: 100%;
    }

    span {
      color: #000;
      font-weight: 600;
    }
  }
}

.accordion {
  .accordion-item {
    box-shadow: 0 0 15px #f1f1f1;
    padding: 15px;
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid #f2f2f2;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .accordion-button {
      font-size: 18px;
      font-weight: 500;

      &:not(.collapsed) {
        border: 0;
        background-color: transparent;
        box-shadow: none;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.salesforce-tools-bx {
  display: block;
  padding: 20px 12px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
  background-color: #FFF;
  width: 100%;

  img {
    height: 80px;
    object-fit: contain;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }
}

// Responsive CSS

@media (max-width:1199px) {
  .banner-section {
    .section-title {
      h1 {
        font-size: 3.6rem;
      }
    }
  }

  .section-title {
    h1 {
      font-size: 3.6rem;
    }
  }

  .dots-shape-animate {
    display: none;
  }

  .cta {
    h3 {
      font-size: 2.2rem;
    }
  }
}

@media (max-width:991px) {
  .image-title-box {
    padding: 1.5rem;

    h3 {
      font-size: 1.8rem;
    }

    p {
      font-size: 14px;
    }
  }

  .cta {
    h3 {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  .blog-featured {
    h1 {
      font-size: 2.4rem;
    }
  }

  .blog-sidebar {
    margin-top: 30px;
  }
  
  .in-list {
    margin-top: 25px;
  }
}

@media (max-width:767px) {
  .subtitle {
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 2rem;

    h1 {
      font-size: 2.4rem;
    }

    h2 {
      font-size: 2.4rem;
    }

    p {
      font-size: 15px;
    }
  }

  .image-radius-design {
    margin-top: 2rem;
  }

  .banner-section {
    .section-title {
      h1 {
        font-size: 2.8rem;
      }
    }
  }

  .f-size-18 {
    font-size: 16px;
  }

  .blog-featured {
    h1 {
      font-size: 2.2rem;
    }
  }

  .repeat-section {
    padding: 4rem 0;
  }

  .about-section {
    .our-company-media {
      display: none;
    }
  }
  .salesforce-training-banner {
    padding: 4rem 0;
    p {
      font-size: 16px;
    }
    .section-title {
      margin-bottom: 15px;
      p {
        font-size: 18px;
      }
    }
  }
  .accordion {
    .accordion-item {
      padding: 10px;
      .accordion-button {
        font-size: 16px;
      }
    }
  }
}