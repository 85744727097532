.client-testimonails {
    padding: 7rem 0;
    overflow: hidden;
}

.slick-list, .slick-slider, .slick-track {
    display: flex;
}
.slick-track {
    >div {
        display: flex;
    }
}

.testimonail-slider {
    padding: 0 5rem;

    .slick-next,
    .slick-prev {
        // box-shadow: 0 0 15px rgba(0, 0, 0, .2);
        border: 1px solid #2E5495;
        border-radius: 5px;

        &:hover {
            border-color: #bc2e31;
        }
    }

    .slick-slide {
        padding: 1.5rem;
        display: flex;
        height: 100%;
    }

    .slide-bx {
        height: 100%;
        &:focus {
            outline: none;
        }
    }

    .client-bx {
        display: block;
        box-shadow: 0 0 15px rgba(0, 0, 0, .2);
        border: 1px solid #f2f2f2;
        padding: 2.5rem 3.5rem;
        border-radius: 10px;
        text-align: center;
        position: relative;
        overflow: hidden;

        &::before,
        &::after {
            background: #f4f6fc;
            content: '';
            position: absolute;
            z-index: -1;
        }

        &::after {
            height: 100%;
            left: 0%;
            top: 0;
            transform: skew(0deg);
            transition-duration: 0.6s;
            transform-origin: bottom left;
            width: 0;
        }

        &:hover {
            &:after {
                height: 100%;
                width: 100%;
                transform-origin: top right;
                right: 0rem;
                left: unset;
            }
        }

        .client-image {
            width: 8rem;
            height: 8rem;
            border-radius: 100%;
            margin: 0 auto 15px;
            padding: 7px;
            border: 2px solid #04213F;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .client-feedbacks {
            font-size: 16px;
            color: #080a3c;
        }

        .client-name {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 2rem;

            span {
                font-weight: 400;
            }
        }
    }
}

@media (max-width:991px) {
    .testimonail-slider {
        padding: 0rem;
    }
}
@media (max-width:767px) {
    
}