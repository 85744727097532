@media (min-width:1600px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width:1399px) {
    .hire-box {
        .hire-list-icon {
            li {
                width: calc(100%/3 - 5px);
                &:last-child {
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (max-width:1199px) {
    .home-banner {
        padding-left: 2rem;
        &:after {
            display: none;
        }
    }
    .slick-dots {
        left: 0;
        right: 0;
    }
    .banner-section {
        .section-title {
            h2 {
                font-size: 3.6rem;
            }
        }
    }
    .section-title {
        h2 {
            font-size: 3rem;
        }
    }
    .company-stats {
        .stats-bx {
            padding: 1rem;
        }
    }
    .about-section {
        .about-stats {
            .years-count {
                font-size: 4rem;
            }
            span {
                font-size: 2.2rem;
            }
        }
    }
    .company-descripition {
        h2 {
            font-size: 3rem;
        }
    }
    .founder-detail {
        .founder-name {
            h2 {
                font-size: 3rem;
            }
        }
    }
    .box-design {
        h2 {
            font-size: 3.6rem;
        }
    }
    .office-address {
        .address-item {
            margin: 1.5rem 0;
        }
    }
}
@media (max-width:991px) {
    .slick-next,
    .slick-prev {
        display: none !important;
    }

    .main-banner .slick-dots li button:before {
        color: #FFF;
        opacity: 1;
    }

    .slick-dots {
        li button:before {
            color: #04213f;
            opacity: 1;
        }
    }

    .client-testimonails, .portfolio-slider {
        .slick-dots {
            bottom: -30px;
        }
    }

    .slick-dots {
        bottom: 20px;
        left: 0;

        li {
            opacity: 1;

            &.slick-active button:before {
                color: #bc2e31;
                opacity: 1;
            }

            button:before {
                font-size: 14px;
            }
        }
    }

    .about-section {
        .about-content {
            padding-left: 0;
            // margin-top: 3rem;
        }
    }

    .why-choose {
        .image-radius-design {
            margin-top: 3rem;
        }
    }

    .section-title {
        h2 {
            font-size: 2.4rem;
        }

        p {
            font-size: 16px;
        }
    }
    .banner-section {
        .section-title {
            h2 {
                font-size: 2.6rem;
            }
        }
    }

    .portfollio-section {
        .portfolio-content {
            h3 {
                font-size: 2.2rem;
            }

            .project-category {
                font-size: 16px;
            }
        }
    }

    .banner-section {
        .about-content {
            max-width: 100%;
        }
    }
    .company-descripition {
        margin-top: 3rem;
    }
    .box-design {
        margin: 1.5rem 0;
    }
    .our-success-section {
        .success-bx {
            margin: 0 0 2rem;
        }
    }
    .our-team-section {
        .team-bx {
            margin: 0 0 5rem;
        }
    }
    .banner-section {
        .image-radius-design {
            margin-top: 3rem;
        }
    }
    .about-industry-image {
        padding-left: 0;
        margin-top: 3rem;
    }
    
    
}

@media (max-width:767px) {
    .company-stats {
        .stats-bx {
            margin: 10px 0;
            padding: 1.5rem;
        }
    }

    .company-descripition {
        margin-top: 3rem;

        h2 {
            font-size: 2.4rem;
        }
    }

    .founder-detail {
        .founder-name {
            h2 {
                font-size: 3rem;
            }

            p {
                font-size: 16px;
            }
        }
    }

    .box-design {
        padding: 2rem;
        margin: 1rem 0;

        h2 {
            font-size: 2.4rem;
        }

        p {
            font-size: 16px;
        }
    }

    .our-success-section {
        .success-bg {
            padding: 3rem;
        }

        .success-bx {
            margin: 1rem 0;
        }
    }

    .our-team-section {
        .team-bx {
            margin: 2rem 0;
        }
    }
    .about-image, .our-company-media {
        img {
            width: 100%;
        }
    }
    .about-section {
        .about-stats {
            .years-count {
                font-size: 3rem;
            }
            span {
                font-size: 2rem;
            }
        }
    }
    .company-stats {
        .stats-bx {
            .count-stats {
                font-size: 3rem;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .repeat-section {
        padding: 3rem 0;
    }
    h3 {
        font-size: 2rem;
        line-height: 1.5;
    }
    .hire-box {
        .hire-list-icon {
            li {
                width: calc(100%/2 - 5px);
            }
        }
    }
        
}