$color_1: #031b42;
$color_2: #ffffff;
$color_3: #ff612f;
$color_4: #4a6f8a;
$color_5: #bc2e31;
$color_6: #fff;
$background-color_1: transparent;
$background-color_2: #ffffff;
$background-color_3: #bc2e31;
$background-color_4: #031b42;
$border-color_1: #031b42;
$border-color_2: #ffffff;

/*================================================
Navbar Area CSS
=================================================*/
/* Navbar toggler */
.navbar-area {
  width: 100%;
  background-color: #FFF;
  height: auto;
  z-index: 1040;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #031b4212;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: $background-color_2 !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0;
  padding-bottom: 0;

  .main-nav {
    .navbar {
      .others-options {
        .optional-btn {
          color: $color_1;

          i {
            color: $color_1;
          }

          &::before {
            border-color: $border-color_1;
          }

          &:hover {
            color: $color_2;

            i {
              color: $color_2;
            }
          }

          &:focus {
            color: $color_2;

            i {
              color: $color_2;
            }
          }
        }
      }
    }
  }
}

body.lightHeader {
  .navbar-brand {
    img {
      filter: brightness(0) invert(1);
    }
  }

  .main-nav {
    .navbar {
      .navbar-nav {
        .nav-item {
          a {
            color: #FFF;

            &:hover {
              color: $color_5;
            }
          }
        }
      }
    }
  }
}

.main-nav {
  background-color: $background-color_1;

  .navbar {
    background-color: $background-color_1;
    padding-right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;

    .navbar-brand {
      padding: 0;
      max-width: 220px;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;
    }

    .navbar-nav {
      margin-left: 110px;

      .nav-item {
        position: relative;
        padding: 0;

        a {
          font-size: 15px;
          font-weight: 500;
          color: $color_4;
          padding-left: 0;
          padding-right: 0;
          padding-top: 30px;
          padding-bottom: 30px;
          margin-left: 13px;
          margin-right: 13px;
          white-space: nowrap;

          &:hover {
            color: $color_5;
          }

          i {
            font-size: 16px;
            position: relative;
            top: 2px;
            display: inline-block;
            margin-left: -3px;
            margin-right: -3px;
          }
        }

        a.active {
          color: $color_5;
        }

        &:last-child {
          a {
            margin-right: 0;
          }
        }

        &:first-child {
          a {
            margin-left: 0;
          }
        }

        &:hover {
          a {
            color: $color_5;
          }

          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
            margin-top: 0;
          }
        }

        .dropdown-menu {
          -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          position: absolute;
          border: none;
          top: 80px;
          left: 0;
          width: 250px;
          z-index: 99;
          display: block;
          opacity: 0;
          visibility: hidden;
          border-radius: 0;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          margin-top: 20px;
          border-top: 2px solid #bc2e31;
          padding: 0;

          li {
            padding: 0;

            a {
              padding: 13px 20px 11px;
              margin: 0;
              position: relative;
              color: $color_4;
              border-bottom: 1px dashed #e5e5e5;
              font-size: 14px;
              font-weight: 500;

              &:hover {
                color: $color_5;
              }

              &:focus {
                color: $color_5;
              }

              i {
                float: right;
              }

              .new {
                position: absolute;
                top: 8px;
                right: -10px;
                background: red;
                color: $color_6;
                font-size: 8px;
                padding: 0 3px;
                border-radius: 20px;
              }
            }

            a.active {
              color: $color_5;
            }

            &:last-child {
              a {
                border-bottom: none;
              }
            }

            .dropdown-menu {
              left: -100%;
              top: 15px;
              opacity: 0;
              visibility: hidden;

              li {
                a {
                  color: $color_4;

                  &:hover {
                    color: $color_5;
                  }

                  &:focus {
                    color: $color_5;
                  }
                }

                a.active {
                  color: $color_5;
                }

                .dropdown-menu {
                  left: 100%;
                  top: 15px;
                  opacity: 0;
                  visibility: hidden;

                  li {
                    a {
                      color: $color_4;

                      &:hover {
                        color: $color_5;
                      }

                      &:focus {
                        color: $color_5;
                      }
                    }

                    a.active {
                      color: $color_5;
                    }

                    .dropdown-menu {
                      left: -100%;
                      top: 15px;
                      opacity: 0;
                      visibility: hidden;

                      li {
                        a {
                          color: $color_4;

                          &:hover {
                            color: $color_5;
                          }

                          &:focus {
                            color: $color_5;
                          }
                        }

                        a.active {
                          color: $color_5;
                        }

                        .dropdown-menu {
                          left: -100%;
                          top: 15px;
                          opacity: 0;
                          visibility: hidden;

                          li {
                            a {
                              color: $color_4;

                              &:hover {
                                color: $color_5;
                              }

                              &:focus {
                                color: $color_5;
                              }
                            }

                            a.active {
                              color: $color_5;
                            }

                            .dropdown-menu {
                              left: -100%;
                              top: 15px;
                              opacity: 0;
                              visibility: hidden;

                              li {
                                a {
                                  color: $color_1;

                                  &:hover {
                                    color: $color_2;
                                    background-color: $background-color_3;
                                  }

                                  &:focus {
                                    color: $color_2;
                                    background-color: $background-color_3;
                                  }
                                }

                                a.active {
                                  color: $color_2;
                                  background-color: $background-color_3;
                                }

                                .dropdown-menu {
                                  left: -100%;
                                  top: 15px;
                                  opacity: 0;
                                  visibility: hidden;

                                  li {
                                    a {
                                      color: $color_1;

                                      &:hover {
                                        color: $color_2;
                                        background-color: $background-color_3;
                                      }

                                      &:focus {
                                        color: $color_2;
                                        background-color: $background-color_3;
                                      }
                                    }

                                    a.active {
                                      color: $color_2;
                                      background-color: $background-color_3;
                                    }
                                  }
                                }

                                &:hover {
                                  .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: 0;
                                  }
                                }
                              }

                              li.active {
                                a {
                                  color: $color_2;
                                  background-color: $background-color_3;
                                }
                              }
                            }

                            &:hover {
                              .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: 0;
                              }
                            }
                          }

                          li.active {
                            a {
                              color: $color_5;
                            }
                          }
                        }

                        &:hover {
                          .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 0;
                          }
                        }
                      }

                      li.active {
                        a {
                          color: $color_5;
                        }
                      }
                    }

                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: 0;
                      }
                    }
                  }

                  li.active {
                    a {
                      color: $color_5;
                    }
                  }
                }

                &:hover {
                  .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    top: 0;
                  }
                }
              }

              li.active {
                a {
                  color: $color_5;
                }
              }
            }

            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                top: 0;
              }
            }
          }

          li.active {
            a {
              color: $color_5;
            }
          }
        }

        .mega-dropdown-menu {
          width: 500px;
          padding: 0 10px 5px;

          h3 {
            font-size: 17px;
            margin-top: 20px;
            border-bottom: 1px dashed #e5e5e5;
            margin-bottom: 0;
            padding-bottom: 10px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              bottom: -1px;
              left: 0;
              background: #bc2e31;
              width: 30px;
              height: 1px;
            }
          }

          .nav-item {
            width: 50%;
            float: left;
            padding: 0 10px;

            a {
              padding-left: 0;
              padding-right: 0;
              border-bottom: 1px dashed #e5e5e5 !important;

              &:last-child {
                border-bottom: none !important;
              }
            }
          }
        }
      }

      .nav-item.active {
        a {
          color: $color_5;
        }
      }
    }

    .others-options {
      .default-btn {
        color: $color_2;
        background-color: $background-color_3;

        i {
          color: $color_2;
        }

        span {
          background-color: $background-color_4;
        }
      }

      .optional-btn {
        margin-right: 15px;
        color: $color_2;

        i {
          color: $color_2;
        }

        &::before {
          border-color: $border-color_2;
        }

        span {
          background-color: $background-color_4;
        }

        &:hover {
          &::before {
            border-color: $border-color_1;
          }
        }

        &:focus {
          &::before {
            border-color: $border-color_1;
          }
        }
      }
    }
  }
}

.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    .icon-bar {
      background-color: #bc2e31;
    }
  }

  &:focus {
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .icon-bar {
    width: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    background: #221638;
    height: 4px;
    display: block;
    border-radius: 3px;

    &.top-bar {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transform-origin: 10% 10%;
      transform-origin: 10% 10%;
      left: 4px;
      position: relative;
    }

    &.middle-bar {
      opacity: 0;
      margin: 5px 0;
    }

    &.bottom-bar {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: 10% 90%;
      transform-origin: 10% 90%;
      left: 4px;
      top: -2px;
      position: relative;
    }
  }
}

.navbar-toggler.collapsed {
  .top-bar {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    left: 0;
  }

  .middle-bar {
    opacity: 1;
    width: 20px;
    margin-left: auto;
  }

  .bottom-bar {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    left: 0;
    top: 0px;
  }
}

.mobile-togg-btn {
  display: none;
}

.others-options {
  display: flex;
  align-items: center;

  a {
    display: flex;
  }

  .user-login-dropdown {
    margin-left: 15px;
    position: relative;
    z-index: 11;

    .user-dropdown-button {
      background-color: #2957a4;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: #FFF;
      cursor: pointer;
      transition: .3s;

      svg {
        fill: #FFF;
      }

      &:hover {
        background-color: #bc2e31;
      }
    }

    .user-dropdown-list {
      position: absolute;
      top: 66px;
      right: 0;
      background-color: #FFF;
      padding: 15px 20px;
      box-shadow: 0 0 5px #ccc;
      border-radius: 15px;
      min-width: 160px;

      a {
        display: block;
        margin: 10px 0;

        &:not(:last-child) {
          padding-bottom: 10px;
          border-bottom: 1px solid #f1f1f1;
        }
      }
    }
  }
}

// Responsive Css
@media (min-width:5000px) {
  .main-nav {
    .navbar {
      .navbar-nav {
        margin-left: 50px;
      }
    }
  }
}

@media (max-width:1400px) {
  .main-nav {
    .navbar {
      .navbar-brand {
        max-width: 180px;
      }

      .navbar-nav {
        margin-left: 50px;
      }
    }
  }
}

@media (max-width:1366px) {
  .main-nav {
    .navbar {
      .navbar-brand {
        max-width: 180px;
      }

      .navbar-nav {
        margin-left: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .main-nav {
    .container {
      max-width: 100%;
    }

    .navbar {
      .navbar-nav {
        margin-left: 20px;

        .nav-item {
          a {
            margin-left: 6px;
            margin-right: 6px;
          }
        }
      }
      .others-options {
        .default-btn {
          padding: 10px 15px;
        }
      }

      .navbar-brand {
        max-width: 190px;
      }

      .others-options {
        margin-left: 15px !important;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  body {
    padding-top: 60px;
  }

  .navbar-area {
    background-color: $background-color_2;
    position: relative;
    border-bottom: 1px solid #eeeeee;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .navbar-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;

    .main-nav {
      width: 100%;

      .others-options {
        margin-left: auto !important;
      }
    }

    .is-sticky {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .navbar-expand-md {
    .navbar-toggler {
      display: block;
    }

    .navbar-nav {
      flex-direction: column;
      height: calc(100vh - 100px);
      overflow: auto;
    }
  }

  #navbarCustom {
    width: 100%;
    top: 59px;
    right: 0;
    position: fixed;
    height: calc(100vh - 59px);
    background: #04213f;
    z-index: 99999;
    transform: translateX(200%);
    transition: all 0.3s ease-in-out;
    display: block !important;

    &.show {
      transform: unset;
      width: 100% !important;
    }
  }

  .navbar-toggler {
    .icon-bar {
      width: 26px;
      height: 3px;
    }
  }

  .main-nav {
    .container {
      max-width: 720px;
    }

    .navbar {
      .navbar-brand {
        max-width: 140px;
      }

      .navbar-nav {
        margin-left: 0;
        padding: 20px;

        .nav-item {
          a {
            margin: 0;
            padding: 10px 0;

            .bx {
              display: none;
            }
          }

          .dropdown-menu {
            position: initial;
            opacity: 1;
            visibility: visible;
            width: 100%;
            margin-top: 0;
            background-color: transparent;
            border-top: 0;
            display: none;
            max-height: 200px;
            overflow: auto;

            &.show {
              display: block;
            }

            li {
              a {
                color: #ffffff;
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .navbar-area {
    padding: 10px 0;
  }

  .default-btn {
    padding: 8px 15px;
  }

  .navbar-toggler {
    order: 3;
    margin-left: 15px;
  }

  .others-options {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile-togg-btn {
    position: absolute;
    top: 7px;
    right: 0;
    width: 26px;
    height: 26px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  body.nav-open {
    overflow: hidden;
  }
}

@media (max-width:767px) {
  .main-nav {
    .container {
      max-width: 100%;
    }

    .navbar {
      .navbar-brand {
        max-width: 120px;
      }

      .others-options {
        .default-btn {
          font-size: 14px;
        }

        .user-login-dropdown {
          .user-dropdown-button {
            width: 36px;
            height: 36px;

            svg {
              width: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:360px) {
  .main-nav {
    .navbar {
      .navbar-brand {
        max-width: 110px;
      }

      .others-options {
        .default-btn {
          padding: 6px 11px;
          font-size: 12px;
        }

        .user-login-dropdown {
          margin-left: 8px;
        }
      }
    }

    .navbar-toggler {
      margin-left: 8px;
    }
  }
}