$optional-color: #ff612f;
$black-color: #080a3c;
$white-color: #ffffff;
$main-color: #bc2e31;
$transition: .5s;

/*================================================
Partner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
    background-color: #04213F;
    overflow: hidden;

    &:before {
        content: "";
        left: -28px;
        z-index: -1;
        bottom: -280px;
        width: 545.24px;
        height: 545.85px;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url(../../../../public/images/dots-pattern.svg);
    }

    .slide-bx {
        overflow: hidden;
    }

    .slick-slide>div {
        display: flex;
    }

    .slick-slide>div:focus,
    .slide-bx:focus {
        outline: none;
    }

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }

        ;

        .row {
            margin: {
                left: 0;
                right: 0;
            }

            ;

            .col-lg-5,
            .col-lg-7 {
                padding: {
                    left: 0;
                    right: 0;
                }

                ;
            }
        }
    }
}

.banner-section {
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }

        ;

        .row {
            margin: {
                left: 0;
                right: 0;
            }

            ;

            .col-lg-7,
            .col-lg-5 {
                padding: {
                    left: 0;
                    right: 0;
                }

                ;
            }
        }
    }
}

.main-banner-content {
    width: 100%;
    height: 100%;

    .content {
        max-width: 500px;

        margin: {
            left: auto;
            top: 80px;
        }

        ;

        h1 {
            font: {
                size: 4rem;
                weight: 700;
            }

            ;

            span {
                display: inline-block;
                color: $optional-color;
            }
        }

        p {
            margin-top: 1.8rem;
            max-width: 540px;
        }

        .default-btn {
            margin-top: 20px;
            background-color: $black-color;

            i {
                color: $optional-color;
            }
        }
    }
}

.banner-content {
    padding: {
        top: 215px;
        bottom: 125px;
    }

    ;

    .content {
        max-width: 775px;
        padding-right: 150px;

        margin: {
            left: auto;
        }

        ;

        h1 {
            font: {
                size: 57px;
                weight: 600;
            }

            ;

            span {
                display: inline-block;
                color: $optional-color;
            }
        }

        p {
            margin-top: 18px;
            max-width: 540px;
        }

        .default-btn {
            margin-top: 15px;
            background-color: $black-color;

            i {
                color: $optional-color;
            }
        }
    }
}

.banner-img {
    height: 100%;
    width: 100%;
    border-radius: 0 0 0 80px;

    background: {
        color: $optional-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    ;
    position: relative;

    img {
        display: none;
    }
}

.banner-content-slides {
    padding-right: 40px;

    &.owl-theme {
        .owl-nav {
            text-align: left;
            margin-top: 50px;

            [class*=owl-] {
                color: $black-color;
                font-size: 25px;
                margin: 0 5px;
                background: #f4f6fc;
                border-radius: 0;
                transition: $transition;
                width: 45px;
                height: 45px;
                line-height: 45px;
                text-align: center;

                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }

                &.owl-prev {
                    border-radius: 0 0 0 10px;
                    margin-left: 0;
                }

                &.owl-next {
                    margin-right: 0;
                    border-radius: 0 10px 0 0;
                }
            }
        }
    }
}

/* Animate banner image */
.animate-#bc2e31 {
    position: relative;
}

.animate-#bc2e31 img {
    display: block !important;
    -webkit-animation: border-transform 10s linear infinite alternate forwards;
    animation: border-transform 10s linear infinite alternate forwards;
    margin: auto;
}

@-webkit-keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

@keyframes border-transform {

    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }

    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }

    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }

    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }

    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }

    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }

    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1.0);
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

.home-banner {
    position: relative;
    height: 100%;

    img {
        height: calc(100vh - 92px);
        object-fit: cover;
        width: 100%;
        z-index: -2;
        position: relative;
    }

    &:after {
        top: 0;
        width: 100%;
        left: -1px;
        content: "";
        z-index: 2;
        height: 100%;
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../../../../public/images/banner-home-overly.png);
    }

    &:before {
        top: 15px;
        content: "";
        z-index: -1;
        width: 841px;
        right: 760px;
        height: 208px;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url(../../../../public/images/banner-overly.svg);
    }
}

.box {
    background-color: #04213F;
    color: #FFF;
    width: 100%;
    height: 300px;
}

/*RESPONSIVE CSS*/
@media (max-width:1280px) {
    .main-banner-content {
        .content {
            max-width: 450px;
            h1 {
                font-size: 3.4rem;
            }
        }
    }
}
@media (max-width:1199px) {
    .main-banner-content {
        .content {
            max-width: 350px;
            h1 {
                font-size: 3rem;
            }
        }
    }
    
}
@media (max-width:991px) {
    .main-banner-content {
        .content {
            max-width: 95%;
            margin: 50px auto ;
            text-align: center;
            .subtitle, h1, p {
                text-align: center;
            }
            h1 {
                font-size: 3rem;
            }
            p {
                margin: 1.8rem auto 0;
                max-width: 90%;
            }
        }
    }
}
@media (max-width:767px) {
    .main-banner-content {
        .content {
            margin: 40px 0;

            h1 {
                font-size: 2.6rem;
            }
        }
    }

    .main-banner {
        .slick-slider {
            padding: 0 15px;
        }
    }
}

.home-page-video {
    position: relative;
    height: 80vh;
    overflow: hidden;
    background-color: #000;
    z-index: 0;
    // background: url(../../../../public/images/video-thumbnail-bg.jpg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0px;
        background-color: rgba(0, 0, 0, .5);
        z-index: -1;
    }
}
video.home-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    position: relative;
    overflow: hidden;
}
.video-text {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    padding: 0 20px;
    .subtitle {
        text-transform: initial;
    }
    h1 {
        font-size: 7.2rem;
    }
    p {
        font-size: 2.2rem;
    }
    .default-btn {
        margin-top: 2rem;
    }
}


@media (max-width:1199px) {
    .video-text {
        h1 {
            font-size: 6rem;
        }
    }    
}

@media (max-width:991px) {
    .video-text {
        h1 {
            font-size: 5rem;
        }
    }
    
}

@media (max-width:767px) {
    .video-text {
        h1 {
            font-size: 3.6rem;
        }
    }
    
}